<template>
  <div>
    <aside
      class="relative text-[#76bdeb] flex h-[100vh] bg-white transition-all duration-300 shadow-lg overflow-y-auto w-[330px] rounded-lg border border-gray-200"
    >
      <div class="w-16 flex flex-col items-center bg-gray-200">
        <div
          v-for="org in this.getOrganization"
          :key="org.organization_id"
          class="flex flex-col mt-3"
        >
          <div class="relative flex w-full group items-center cursor-pointer">
            <span
              @click="toggleOrganization(org)"
              class="bg-white border hover:bg-gray-100 transition-all rounded-md items-center justify-center w-10 h-10 flex text-gray-600 font-semibold flex-col"
              :class="[
                this.getSelectedOrganization.organization_id ==
                org.organization_id
                  ? 'border-gray-400 border-2'
                  : '',
              ]"
            >
              {{
                org.organization_name
                  .split(" ")
                  .slice(0, 2)
                  .map((name) => name.charAt(0).toUpperCase())
                  .join("")
              }}
            </span>
            <span
              class="absolute hidden border group-hover:block transition-all text-start left-10 bg-gray-100 w-56 mx-2 text-sm text-gray-800 p-2 shadow-lg rounded-lg px-4"
            >
              {{ org.organization_name }}
            </span>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full h-[100%] overflow-auto">
        <div class="menu flex flex-col gap-2 justify-center items-center">
          <div class="py-3 text-start text-sm flex flex-col gap-2 w-[90%]">
            <RouterLink to="/home-view" class="">
              <div
                class="px-2 py-1.5 flex gap-2 items-center border cursor-pointer bg-gray-100 rounded text-gray-700"
                :class="{
                  'bg-white  border border-blue-500':
                    $route.name === 'HomeView',
                  'text-gray-700': $route.name !== 'HomeView',
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                <p class="pt-1">Home</p>
              </div>
            </RouterLink>
            <RouterLink
              v-if="
                this.getSelectedOrganization.user_level == 'Level 1' ||
                this.getSelectedOrganization.user_level == 'Level 2' ||
                this.getSelectedOrganization.user_level == 'Level 3'
              "
              to="/organization/tasks-details"
              class=""
            >
              <div
                class="px-2 py-1.5 flex gap-2 items-center cursor-pointer border bg-gray-100 rounded text-gray-700"
                :class="{
                  'bg-white  border border-blue-500':
                    $route.name === 'orgTaskDetails',
                  'text-gray-700': $route.name !== 'orgTaskDetails',
                }"
              >
                <img
                  src="@/assets/checklist.png"
                  alt=""
                  class="h-4 w-4 object-contain"
                />
                <p class="pt-1">Tasks Details</p>
              </div>
            </RouterLink>
            <RouterLink to="/mytasks" class="">
              <div
                class="px-2 py-1.5 flex gap-2 items-center cursor-pointer border bg-gray-100 rounded text-gray-700"
                :class="{
                  'bg-white  border border-blue-500': $route.name === 'MyTasks',
                  'text-gray-700': $route.name !== 'MyTasks',
                }"
              >
                <img
                  src="@/assets/checklist.png"
                  alt=""
                  class="h-4 w-4 object-contain"
                />
                <p class="pt-1">My Tasks</p>
              </div>
            </RouterLink>
            <RouterLink to="/organization-info" class="">
              <div
                class="hidden px-2 py-1.5 flex gap-2 items-center cursor-pointer border bg-gray-100 rounded text-gray-700"
                :class="{
                  'bg-white  border border-blue-500':
                    $route.name === 'OrganizationInfo',
                  'text-gray-700': $route.name !== 'OrganizationInfo',
                }"
              >
                <img
                  src="@/assets/checklist.png"
                  alt=""
                  class="h-4 w-4 object-contain"
                />
                <p class="pt-1">Organization Info</p>
              </div>
            </RouterLink>
            <br />
            <br />
            <div class="flex justify-between items-center">
              <h1 class="text-sm text-gray-700">Workspace details</h1>
              <button
                v-if="
                  this.getSelectedOrganization.user_level == 'Level 1' ||
                  this.getSelectedOrganization.user_level == 'Level 2' ||
                  this.getSelectedOrganization.user_level == 'Level 3'
                "
                @click="toggleOffCanvas"
                class="h-4 w-4 text-black hover:bg-blue-500 hover:text-white transition-all bg-gray-100 rounded-full flex items-center justify-center"
              >
                +
              </button>
            </div>
            <template v-if="overAllWorkspace_details">
              <div
                v-for="workspace in overAllWorkspace_details"
                :key="workspace.id"
                class="px-2 py-1.5 cursor-pointer border bg-white shadow-md rounded text-gray-700 overflow-auto"
                :class="
                  workspace.id == selectedWorkspaceId
                    ? 'shadow-lg border border-gray-300'
                    : ''
                "
              >
                <div
                  @click="selectWorkspace(workspace.id)"
                  class="flex gap-2 items-center justify-between"
                >
                  <div class="flex gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                      />
                    </svg>

                    <p>{{ workspace.name }}</p>
                  </div>

                  <i
                    :class="
                      workspace.id == selectedWorkspaceId
                        ? 'rotate-180'
                        : 'rotate-0'
                    "
                    class="transition-transform duration-300 text-purple-900"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </i>
                </div>
                <div
                  v-if="workspace.id === selectedWorkspaceId"
                  class="pt-4 pb-2"
                >
                  <ul class="flex flex-col gap-2">
                    <template
                      v-for="project in workspace.workspace_project"
                      :key="project"
                    >
                      <li
                        @click="selectedProject(project.id, project.slug)"
                        class="flex items-center border hover:bg-gray-200 rounded-sm px-2 py-1.5 gap-2 mx-1"
                        :class="
                          project.id == selectedProjectId
                            ? 'border-blue-500 bg-blue-50 shadow-md'
                            : ''
                        "
                      >
                        <img
                          src="@/assets/project_icon.png"
                          alt=""
                          class="h-4 w-4 object-contain"
                        />
                        <div class="flex gap-2 items-center justify-between">
                          <div class="flex gap-2 items-center line-clamp-1">
                            <p class="line-clamp-1 text-sm">
                              {{ project.name }}
                            </p>
                          </div>
                        </div>
                      </li>
                    </template>
                    <button
                      v-if="
                        this.getSelectedOrganization.user_level == 'Level 1' ||
                        this.getSelectedOrganization.user_level == 'Level 2' ||
                        this.getSelectedOrganization.user_level == 'Level 3'
                      "
                      @click="projectCanvas(workspace.id, workspace.name)"
                      class="bg-[#e5f3fb] border border-[#76bdeb] justify-center text-gray-900 mx-auto px-5 py-1 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-lg"
                    >
                      + Add
                    </button>
                  </ul>
                </div>
              </div>
            </template>

            <!-- filtered workspace -->
            <template v-if="workspace_details">
              <div
                v-for="workspace in workspace_details"
                :key="workspace.id"
                class="px-2 py-1.5 cursor-pointer border bg-white shadow-md rounded text-gray-700 overflow-auto"
                :class="
                  workspace.id == selectedWorkspaceId
                    ? 'shadow-lg border border-gray-300'
                    : ''
                "
              >
                <div
                  @click="selectWorkspace(workspace.id)"
                  class="flex gap-2 items-center justify-between"
                >
                  <div class="flex gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                      />
                    </svg>

                    <p>{{ workspace.name }}</p>
                  </div>

                  <i
                    :class="
                      workspace.id == selectedWorkspaceId
                        ? 'rotate-180'
                        : 'rotate-0'
                    "
                    class="transition-transform duration-300 text-purple-900"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </i>
                </div>
                <div
                  v-if="workspace.id === selectedWorkspaceId"
                  class="pt-4 pb-2"
                >
                  <ul class="flex flex-col gap-2">
                    <template
                      v-for="project in workspace.project"
                      :key="project"
                    >
                      <li
                        @click="selectedProject(project.id, project.slug)"
                        class="flex items-center border hover:bg-gray-200 rounded-sm px-2 py-1.5 gap-2 mx-1"
                        :class="
                          project.id == selectedProjectId
                            ? 'border-blue-500 bg-blue-50 shadow-md'
                            : ''
                        "
                      >
                        <img
                          src="@/assets/project_icon.png"
                          alt=""
                          class="h-4 w-4 object-contain"
                        />
                        <div class="flex gap-2 items-center justify-between">
                          <div class="flex gap-2 items-center line-clamp-1">
                            <p class="line-clamp-1 text-sm">
                              {{ project.name }}
                            </p>
                          </div>
                        </div>
                      </li>
                    </template>
                    <button
                      v-if="
                        this.getSelectedOrganization.user_level == 'Level 1' ||
                        this.getSelectedOrganization.user_level == 'Level 2' ||
                        this.getSelectedOrganization.user_level == 'Level 3'
                      "
                      @click="projectCanvas(workspace.id, workspace.name)"
                      class="bg-[#e5f3fb] border border-[#76bdeb] justify-center text-gray-900 mx-auto px-5 py-1 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-lg"
                    >
                      + Add
                    </button>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="
            this.getSelectedOrganization.user_level == 'Level 1' ||
            this.getSelectedOrganization.user_level == 'Level 2' ||
            this.getSelectedOrganization.user_level == 'Level 3'
          "
          class="flex items-center justify-center mt-auto w-full"
        >
          <div>
            <button
              @click="isPopUp = true"
              class="px-6 py-1 mb-4 border border-gray-300 rounded-md text-gray-700"
            >
              Invite Members
            </button>
          </div>
        </div>
      </div>
    </aside>
    <!-- Add Workspace Form (offcanvas) -->
    <div
      v-if="workspaceCanvas"
      class="bg-[#00000020] fixed left-0 top-0 right-0 h-full z-10 transition-all duration-300 ease-in-out"
    ></div>
    <div
      :class="[
        'fixed right-0 top-0 z-20  h-full bg-white shadow-xl rounded-tl-lg w-[21rem] transition-transform duration-300 ease-in-out',
        workspaceCanvas ? 'translate-x-0' : 'translate-x-full',
      ]"
    >
      <div
        class="flex items-center justify-between bg-blue-600 rounded-l-lg text-white py-4 px-4"
      >
        <h5
          class="mb-0 font-semibold text-medium leading-normal"
          id="offcanvasRightLabel"
        >
          Let's build a Workspace
        </h5>

        <svg
          @click="toggleOffCanvas"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="bg-red-500 h-7 text-white absolute right-3 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>

      <div class="flex-grow overflow-y-auto p-4 text-[.9rem]">
        <form @submit.prevent="workSpacePost" class="flex flex-col gap-y-5">
          <div class="flex flex-col gap-1 items-start">
            <label class="text-slate-900 font-medium">Organization</label>

            <div
              class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2"
            >
              {{ this.getSelectedOrganization.organization_name }}
            </div>
          </div>

          <div class="flex flex-col gap-1 items-start">
            <label for="workspace_name"
              >Name <span class="text-red-500">*</span></label
            >
            <input
              type="text"
              id="workspace_name"
              placeholder="Workspace Name"
              required
              class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
            />
          </div>
          <div class="flex flex-col gap-1 items-start">
            <label for="workspace_img"
              >Image <span class="text-red-500">*</span></label
            >
            <input
              type="file"
              id="workspace_image"
              class="file-upload text-slate-700 bg-slate-100 rounded-md border border-gray-300 w-full text-start focus:ring-2 focus:ring-[#76bdeb]"
            />
          </div>

          <div class="flex flex-col gap-1 items-start">
            <label for="desc">Description</label>
            <textarea
              id="desc"
              cols="30"
              rows="4"
              placeholder="Describe your workspace..."
              class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
            ></textarea>
          </div>
          <div class="flex justify-center">
            <button
              type="submit"
              class="bg-soft-primary border border-primary text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all hover:bg-primary shadow-lg"
            >
              Add Workspace
            </button>
          </div>
        </form>
        <div
          v-if="message"
          :class="status === 200 ? 'text-green-500' : 'text-red-500'"
        >
          {{ message }}
        </div>
      </div>
    </div>

    <!-- project createoffcanvas -->
    <div
      v-if="projectOffcanvss"
      class="bg-[#00000020] fixed left-0 top-0 right-0 h-full z-10 transition-all duration-300 ease-in-out"
    ></div>
    <div
      :class="[
        'fixed right-0 top-0 z-40 w-[21rem] rounded-tl-lg  h-full bg-white shadow-xl transition-transform duration-300 ease-in-out',
        projectOffcanvss ? 'translate-x-0' : 'translate-x-full',
      ]"
    >
      <div
        class="flex items-center justify-between bg-blue-600 rounded-l-lg text-white py-4 px-4"
      >
        <h5
          class="mb-0 font-semibold text-medium leading-normal"
          id="offcanvasRightLabel"
        >
          Add New Project
        </h5>

        <svg
          @click="projectCanvas"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="bg-red-500 h-7 text-white absolute right-3 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>

      <div class="flex-grow overflow-y-auto p-4 text-[.9rem]">
        <form
          ref="project_form"
          @submit.prevent="projectPost"
          class="flex flex-col gap-4"
        >
          <div class="flex flex-col gap-1 items-start">
            <label class="text-slate-900 font-medium">Workspace Name</label>

            <div
              class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2"
            >
              {{ workspaceName }}
            </div>
          </div>

          <div class="flex flex-col gap-1 items-start">
            <label for="project_name">
              Name <span class="text-red-500">*</span>
            </label>
            <input
              id="project_name"
              name="project_name"
              type="text"
              placeholder="Project name"
              required
              class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
            />
          </div>

          <div class="flex flex-col gap-1 items-start">
            <label for="bg_image">Background Image</label>
            <input
              id="bg_image"
              name="bg_image"
              type="file"
              class="file-upload text-slate-700 bg-slate-100 rounded-md border border-gray-300 w-full text-start focus:ring-2 focus:ring-[#76bdeb]"
            />
          </div>

          <div class="flex flex-col gap-1 items-start">
            <label for="desc">Description</label>
            <textarea
              id="desc"
              name="desc"
              cols="30"
              rows="4"
              placeholder="Describe your project..."
              class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
            ></textarea>
          </div>

          <div class="flex justify-center">
            <button
              type="submit"
              class="bg-soft-primary border border-primary text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all hover:bg-primary shadow-lg"
            >
              Add Project
            </button>
          </div>
        </form>
        <div
          v-if="message"
          :class="status === 200 ? 'text-green-500' : 'text-red-500'"
        >
          {{ message }}
        </div>
      </div>
    </div>
    <InviteMemberVue v-if="isPopUp" @closePopUp="isPopUp = false" />
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import InviteMemberVue from "@/components/InviteMember.vue";

export default {
  name: "LeftNavigation",
  props: {
    wsid: {
      type: Number,
      default: null,
    },
    pid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isPopUp: false,
      workspace_details: [],
      overAllWorkspace_details: [],
      selectedWorkspaceId: "",
      selectedProjectId: "",
      workspaceCanvas: false,
      projectOffcanvss: false,
      workspaceId: 0,
      workspaceName: "",
      orgId: "",
      message: null,
      status: null,
    };
  },
  components: { InviteMemberVue },
  computed: {
    ...mapGetters([
      "getSelectedOrganization",
      "getOrganization",
      "getAccessToken",
      "getOrgUser",
      "getRoleAccess",
    ]),
    organization_id() {
      return this.getSelectedOrganization;
    },
  },
  watch: {
    wsid: {
      immediate: true,
      handler(newWsId) {
        if (newWsId) {
          this.selectedWorkspaceId = newWsId;
        }
      },
    },
    pid: {
      immediate: true,
      handler(newPid) {
        if (newPid) {
          this.selectedProjectId = newPid;
        }
      },
    },
    $route: {
      immediate: true,
      handler(newRoute) {
        if (newRoute.name !== "ProjectDashboard") {
          this.selectedWorkspaceId = "";
          this.selectedProjectId = "";
        }
      },
    },
  },
  created() {
    this.workspaceList();
  },
  mounted() {
    this.workspaceList();
  },

  methods: {
    ...mapActions(["setSelectedOrganization"]),
    toggleOrganization(org) {
      this.setSelectedOrganization(org);
      this.organization_id = org.organization_id;
      this.workspaceList();
      this.$router.push({ name: "HomeView" });
    },

    selectedProject(id, slug) {
      this.$emit("selectedProject");
      this.selectedProjectId = id;
      this.$router.push({ name: "ProjectDashboard", params: { slug: slug } });
    },
    selectWorkspace(workspaceId) {
      if (this.selectedWorkspaceId === workspaceId) {
        this.selectedWorkspaceId = null;
      } else {
        this.selectedWorkspaceId = workspaceId;
      }
    },
    toggleOffCanvas() {
      this.workspaceCanvas = !this.workspaceCanvas;
    },
    projectCanvas(id, name) {
      this.workspaceId = id;
      this.workspaceName = name;
      this.projectOffcanvss = !this.projectOffcanvss;
    },
    async projectPost(el) {
      if (this.getRoleAccess.post_access) {
        const formProjectData = new FormData();
        formProjectData.append("name", el.target.elements.project_name.value);
        formProjectData.append("workspace", this.workspaceId);
        formProjectData.append("status", true);
        formProjectData.append("description", el.target.elements.desc.value);
        const bgImageInput = el.target.elements.bg_image;

        if (bgImageInput && bgImageInput.files && bgImageInput.files[0]) {
          formProjectData.append("background_image", bgImageInput.files[0]);
        }

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API}/project/create/`,
            formProjectData,
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            this.message = response.data.message;
            this.status = 200;
            setTimeout(() => {
              this.message = null;
            }, 2000);
            await this.workspaceList();
            this.$refs.project_form.reset();
            this.projectCanvas();
          }
        } catch (error) {
          if (error.response && error.response.status === 400) {
            this.message = error.response.data.message;
            setTimeout(() => {
              this.message = null;
            }, 5000);
          } else {
            console.error("Error creating Project:", error);
          }
        }
      } else {
        alert("You dont have access to add a project");
      }
    },
    async workSpacePost(el) {
      if (this.getRoleAccess.post_access) {
        const formData = new FormData();
        formData.append(
          "organization",
          this.getSelectedOrganization.organization_id
        );
        formData.append("name", el.target.elements.workspace_name.value);
        formData.append("desc", el.target.elements.desc.value);

        const workspaceImageInput = el.target.elements.workspace_image;

        if (
          workspaceImageInput &&
          workspaceImageInput.files &&
          workspaceImageInput.files[0]
        ) {
          formData.append("image", workspaceImageInput.files[0]);
        }

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API}/workspace/create/`,
            formData,
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            this.message = response.data.message;
            setTimeout(() => {
              this.message = null;
            }, 2000);
            this.toggleOffCanvas();
            await this.workspaceList();
          }
        } catch (error) {
          if (error.response && error.response.status === 400) {
            this.message = error.response.data.message;
            setTimeout(() => {
              this.message = null;
            }, 5000);
          } else {
            console.error("API Error:", error);
          }
        }
      } else {
        alert("You dont have access to add a workspace");
      }
    },
    async workspaceList() {
      if (
        this.getSelectedOrganization.user_level == "Level 1" ||
        this.getSelectedOrganization.user_level == "Level 2" ||
        this.getSelectedOrganization.user_level == "Level 3"
      ) {
        try {
          this.workspace_details = [];
          const response = await axios.get(
            `${process.env.VUE_APP_API}/workspace/get/list/?organization__id=${this.getSelectedOrganization.organization_id}`,
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          );
          if (response.data.status === 200) {
            this.overAllWorkspace_details = response.data.data;

            if (this.wsid && this.pid) {
              this.selectedWorkspaceId = this.wsid;
              this.selectedProjectId = this.pid;
            } else {
              this.selectedWorkspaceId = "";
              this.selectedProjectId = "";
            }
          }
        } catch (error) {
          console.error("API call failed:", error);
        }
      } else {
        try {
          this.overAllWorkspace_details = [];
          const response = await axios.get(
            `${process.env.VUE_APP_API}/organization-user/get/list/${this.getOrgUser}/`,
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          );
          if (response) {
            this.workspace_details =
              response.data.profile[0].organization_user.workspace;

            if (this.wsid && this.pid) {
              this.selectedWorkspaceId = this.wsid;
              this.selectedProjectId = this.pid;
            } else {
              this.selectedWorkspaceId = "";
              this.selectedProjectId = "";
            }
          }
        } catch (error) {
          console.error("API call failed:", error);
        }
      }
    },
  },
};
</script>

<style></style>
