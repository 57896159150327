<template>
  <div
    v-if="taskForm"
    class="bg-black z-[999] fixed top-0 left-0 flex justify-center items-center right-0 bottom-0 bg-opacity-10"
  >
    <form
      @submit.prevent="saveChanges"
      class="card text-start bg-white flex flex-col gap-y-4 w-auto relative md:w-[50rem] p-5 rounded-lg"
    >
      <div
        class="flex items-center justify-between bg-blue-600 rounded-lg relative text-white py-4 px-4"
      >
        <h1 class="text-center text-lg font-semibold">Add Task</h1>
        <svg
          @click="closeTaskForm"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="bg-red-500 h-8 text-white absolute right-3 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div
        v-if="this.getSelectedOrganization.organization_special_role"
        class="mb-2 w-full"
      >
        <div>Sub-task</div>
        <select
          v-model="addTaskForm.label"
          class="w-full text-slate-700 text-sm bg-slate-100 rounded-md border border-gray-300 py-2.5 text-start px-2"
        >
          <option value="" selected default>Select sub-task</option>
          <option v-for="label in taskLabel" :key="label.id" :value="label.id">
            {{ label.name }}
          </option>
        </select>
      </div>
      <div class="mb-2 relative">
        <label for="taskname"
          >{{
            this.getSelectedOrganization.organization_special_role
              ? "Task Details"
              : "Name"
          }}
          <b class="text-red-500">*</b></label
        >
        <input
          type="text"
          id="sub_task_name"
          v-model="addTaskForm.name"
          class="rounded-lg bg-slate-100 text-gray-700 outline-none ring-transparent px-4 py-2 w-full"
          :class="[
            addTaskForm.name.length >= 100
              ? 'border-2 border-red-500'
              : 'border-2 border-gray-200',
          ]"
          placeholder="Enter Task name"
          required
        />
        <span
          v-if="addTaskForm.name.length >= 100"
          class="text-xs absolute bottom-12 mt-1 right-0 text-red-500"
          >Task name should not exceed more than 1000 characters !!</span
        >
      </div>
      <div class="flex items-center gap-x-5 justify-between">
        <div class="mb-2 w-full">
          <div for="taskname">Start Date</div>
          <input
            type="date"
            v-model="addTaskForm.start_date"
            class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
          />
        </div>
        <div class="mb-2 w-full">
          <div for="taskname">End Date</div>
          <input
            type="date"
            v-model="addTaskForm.end_date"
            class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
          />
        </div>
        <div class="mb-2 w-full">
          <div>Priority <b class="text-red-500">*</b></div>
          <select
            required
            v-model="addTaskForm.priority"
            class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 w-full py-[9.6px] px-2 text-start focus:ring-2 focus:ring-[#76bdeb]"
          >
            <option value="" selected>Select</option>
            <option v-for="flag in flagList" :key="flag.id" :value="flag.id">
              {{ flag.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex items-center gap-x-5 justify-start">
        <div class="mb-2 w-1/3">
          <label for="taskname">Image</label>
          <input
            type="file"
            @change="fileName"
            class="file-upload text-slate-700 bg-slate-100 rounded-md border border-gray-300 w-full text-start focus:ring-2 focus:ring-[#76bdeb]"
          />
        </div>
        <div
          v-if="!this.getSelectedOrganization.organization_special_role"
          class="mb-2 w-1/3"
        >
          <div>Label</div>
          <select
            v-model="addTaskForm.label"
            class="w-full text-slate-700 text-sm bg-slate-100 rounded-md border border-gray-300 py-2.5 text-start px-2"
          >
            <option value="" selected default>Select Label</option>
            <option
              v-for="label in taskLabel"
              :key="label.id"
              :value="label.id"
            >
              {{ label.name }}
            </option>
          </select>
        </div>
        <div class="mb-2 w-1/3">
          <div>Assign to <b class="text-red-500">*</b></div>
          <select
            required
            v-model="addTaskForm.member_id"
            class="w-full text-slate-700 text-sm bg-slate-100 rounded-md border border-gray-300 py-2.5 text-start px-2"
          >
            <option value="" selected default>Assign to</option>
            <option
              v-for="member in memberList"
              :key="member.id"
              :value="member.id"
            >
              {{ member.organization_user.user.display_name }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="!this.getSelectedOrganization.organization_special_role"
        class="mb-2"
      >
        <label for="taskdescription">Description </label>
        <textarea
          v-model="addTaskForm.description"
          rows="3"
          class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
          placeholder="Describe your task"
        ></textarea>
      </div>
      <button
        :disabled="this.loading || addTaskForm.name.length >= 100"
        class="bg-soft-primary w-36 justify-center mx-auto border border-primary text-gray-900 py-2 rounded-lg text-sm transition-all hover:bg-primary shadow-lg disabled:cursor-not-allowed"
        :class="this.loading ? 'animate-pulse ' : ''"
      >
        {{ !this.loading ? "Submit" : "Loading ..." }}
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    showTaskForm: {
      type: Boolean,
    },
    card: {
      type: Object,
    },
    flagList: Array,
    memberList: Object,
    taskLabel: Object,
  },
  data() {
    return {
      taskForm: this.showTaskForm,
      addTaskForm: {
        name: "",
        description: "",
        taskimage: "",
        start_date: "",
        end_date: "",
        priority: "",
        member_id: "",
        label: "",
      },
      taskImage: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedOrganization",
      "getAccessToken",
      "getOrgUser",
      "getRoleAccess",
    ]),
  },
  methods: {
    fileName(event) {
      this.addTaskForm.taskimage = event.target.files[0];
      this.taskImage = event.target.files[0].name;
    },
    async saveChanges() {
      if (this.getRoleAccess.post_access) {
        try {
          this.loading = true;
          let formData = {
            name: this.addTaskForm.name,
            order: this.card.project_card_task.length,
            description: this.addTaskForm.description
              ? this.addTaskForm.description
              : "",
            project_card: this.card.id,
            flag: this.addTaskForm.priority,
            start_date: this.addTaskForm.start_date,
            end_date: this.addTaskForm.end_date,
            label: this.addTaskForm.label ? this.addTaskForm.label : null,
            document: this.addTaskForm.taskimage,
            created_by: this.getOrgUser,
            assigned_to: this.addTaskForm.member_id,
            status: true,
          };
          await axios
            .post(
              `${process.env.VUE_APP_API}/task/create/`,
              formData,

              {
                params: {
                  organization_accesskey_id:
                    this.getSelectedOrganization.organization_id,
                },
                headers: {
                  Authorization: `Bearer ${this.getAccessToken}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((resp) => {
              if (resp.status === 200) {
                this.closeTaskForm();
                this.$emit("formSubmit", resp.data.data);
              } else {
                console.error("Error while adding task:", resp.data.message);
              }
            });
        } catch (error) {
          console.log("error", error);
        } finally {
          this.loading = false;
        }
      } else {
        alert("You dont have access to add a task");
      }
    },

    closeTaskForm() {
      this.$emit("close-form");
    },
  },
};
</script>
