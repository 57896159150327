<template>
  <div>
    <div>
      <div
        v-if="Task_details"
        class="bg-[#00000030] fixed left-0 top-0 right-0 h-full z-10 transition-all duration-500 ease-in-out"
      ></div>
      <div
        :class="[
          'fixed right-0 transition-transform top-0 z-30 h-full rounded-tl-lg bg-white w-[30rem] shadow-xl duration-300 ease-in-out',
          Task_details ? 'translate-x-0' : 'translate-x-full',
        ]"
      >
        <div class="h-screen flex flex-col">
          <div class="flex-1 overflow-y-auto">
            <div v-if="!taskedit" class="text-start flex flex-col h-full gap-4">
              <div
                class="border-b border-gray-400 flex items-center justify-between bg-blue-600 rounded-tl-lg text-white py-4 px-4"
              >
                <div class="text-xl">Task details</div>
                <div class="flex gap-x-3 items-center ml-auto">
                  <img
                    v-if="
                      this.getSelectedOrganization.organization_user_id ==
                      this.taskAllDetails[0]?.created_by
                    "
                    @click="editTask"
                    src="@/assets/edit.svg"
                    class="w-8 cursor-pointer filter invert"
                    alt="Edit icon"
                  />

                  <svg
                    @click="closeTaskCanvas"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="bg-red-500 h-8 text-white font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex flex-col gap-4 px-6 bg-white">
                <div class="font-semibold taskTitle text-gray-800 text-xl">
                  {{ taskName }}
                </div>
                <div class="flex flex-col gap-3">
                  <div class="flex justify-between">
                    <div
                      class="font-semibold items-center flex gap-x-2 text-gray-800"
                    >
                      <span> Due Date : </span>
                      <img src="@/assets/duetime.svg" class="w-6 h-5" alt="" />
                      <span
                        class="text-gray-600 font-normal"
                        :class="[
                          newDue
                            ? 'text-gray-600'
                            : 'text-gray-600 text-sm font-light',
                        ]"
                        >{{
                          newDue ? formatDateTime(newDue) : "No Due Date"
                        }}</span
                      >
                    </div>
                    <div
                      v-if="
                        this.getSelectedOrganization.organization_user_id ==
                        this.taskAllDetails[0]?.created_by
                      "
                      class="w-48 relative"
                    >
                      <select
                        @change="assignTo"
                        v-model="member_id"
                        class="w-full text-slate-700 text-sm bg-slate-100 rounded-md border border-gray-300 py-1 text-start px-2"
                      >
                        <option value="">Assign to</option>
                        <option
                          v-for="member in memberList"
                          :key="member.id"
                          :value="member.id"
                        >
                          {{ member.organization_user.user.display_name }}
                        </option>
                      </select>
                      <span
                        v-if="tmsg"
                        class="text-green-500 text-xs absolute right-0 top-8"
                        >{{ tmsg }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="font-semibold items-center flex gap-x-2 gap-2 py-1"
                  >
                    Assign to :
                    <img
                      src="@/assets/assignedto.svg"
                      class="h-6 bg-gray-100 rounded-full p-1"
                      alt=""
                    />
                    <span
                      :class="[
                        assignedTo
                          ? 'text-gray-600'
                          : 'text-gray-600 text-sm font-light',
                      ]"
                    >
                      {{ assignedTo ? assignedTo : "No Member assigned" }}
                    </span>
                  </div>
                  <div class="items-center justify-between flex py-1">
                    <span v-if="newFlag" class="flex items-center gap-x-2">
                      <span class="font-semibold"> Priority : </span>
                      <svg
                        width="50px"
                        height="50px"
                        stroke-width="2.5"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        :fill="newFlag.colour_code"
                        :stroke="newFlag.colour_code"
                        class="size-6"
                      >
                        <polyline points="16 32 48 32 36 20 48 8 16 8 16 56" />
                      </svg>
                      <span class="text-sm font-semibold">{{
                        newFlag.name
                      }}</span>
                    </span>
                    <div
                      v-if="
                        this.getSelectedOrganization.organization_user_id ==
                        this.taskAllDetails[0]?.created_by
                      "
                      class="w-48 relative justify-end ml-auto flex"
                    >
                      <select
                        @change="flagUpdate"
                        v-model="flagId"
                        class="w-20 text-slate-700 text-sm bg-slate-100 rounded-md border border-gray-300 py-1 text-start px-2"
                      >
                        <option value="" selected default>Priority</option>
                        <option
                          v-for="flag in flagList"
                          :key="flag.id"
                          :value="flag.id"
                        >
                          {{ flag.name }}
                        </option>
                      </select>
                      <span
                        v-if="flagMsg"
                        class="text-green-500 text-xs absolute right-0 top-8"
                        >{{ flagMsg }}</span
                      >
                    </div>
                  </div>

                  <div
                    v-if="
                      !this.getSelectedOrganization.organization_special_role
                    "
                    class="flex flex-col mt-2"
                  >
                    <label class="font-semibold text-gray-800 text-md"
                      >Description
                    </label>

                    <pre
                      :class="
                        taskDescription.startsWith('https') ||
                        taskDescription.startsWith('http')
                          ? 'break-all'
                          : ''
                      "
                      class="text-gray-600 text-sm font-sans text-wrap taskTitle"
                      >{{ taskDescription }}</pre
                    >
                  </div>

                  <div
                    class="flex gap-x-2 w-full justify-between mb-2 items-center"
                  >
                    <div
                      v-if="
                        this.getSelectedOrganization.organization_user_id ==
                          this.taskAllDetails[0]?.created_by ||
                        this.getUser.id ==
                          this.taskAllDetails[0]?.assigned_to.user.id
                      "
                      class="flex items-center gap-2 px-3 py-2 border rounded-md"
                    >
                      <input
                        type="checkbox"
                        id="task_status"
                        :checked="this.taskCompletedStatus"
                        :disabled="!this.getRoleAccess.patch_access"
                        @change="toggleCompletionStatus"
                        class="w-4 h-4 text-blue-500 cursor-pointer"
                      />
                      <label class="text-gray-600 text-sm">Completed</label>
                    </div>
                    <div
                      v-if="
                        this.getSelectedOrganization.organization_user_id ==
                        this.taskAllDetails[0]?.created_by
                      "
                      @click="archiveCard"
                      class="flex items-center gap-2 px-3 py-2 border rounded-md cursor-pointer"
                    >
                      <svg
                        class="w-6 h-6"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12"
                          stroke="#000000"
                          stroke-width="1.5"
                        />
                        <path
                          d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14Z"
                          stroke="#000000"
                          stroke-width="1.5"
                        />
                        <path
                          d="M12 11L12 17M12 17L14.5 14.5M12 17L9.5 14.5"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span class="text-gray-600 text-sm"> Archive </span>
                    </div>
                  </div>

                  <div v-if="newDoc" class="flex flex-col relative gap-1">
                    <div class="right-1 top-1 absolute flex gap-x-1">
                      <img
                        @click="toggleTaskImage(newDoc)"
                        src="@/assets/imgprev.svg"
                        class="h-10 p-2 border-2 cursor-pointer rounded-lg hover:bg-gray-100 hover:shadow-lg transition-all bg-white"
                        alt="maximize icon"
                      />
                      <img
                        @click="downloadCommentFiles(getSecureUrl(newDoc))"
                        src="@/assets/download_icon.svg"
                        class="h-10 p-2 border-2 cursor-pointer rounded-lg hover:bg-gray-100 hover:shadow-lg transition-all bg-white"
                        alt="download icon"
                      />
                      <!-- delete icon for task image -->
                      <img
                        @click="deleteTaskImage()"
                        src="@/assets/deleteTask.svg"
                        class="h-10 p-2 border-2 cursor-pointer rounded-lg hover:bg-gray-100 hover:shadow-lg transition-all bg-white"
                        alt="delete icon"
                      />
                    </div>
                    <img
                      :src="getSecureUrl(newDoc)"
                      alt="Task Document"
                      class="min-w-full min-h-full border object-contain rounded-lg shadow-sm"
                    />
                  </div>

                  <h4
                    v-if="subtaskdetails"
                    class="font-bold text-gray-700 text-md"
                  >
                    SubTask List
                  </h4>

                  <div
                    v-for="subtask in subtaskdetails"
                    :key="subtask.id"
                    class="flex flex-col gap-1 border-b border-gray-200 pb-2 last:border-b-0"
                  >
                    <div class="flex items-start justify-between gap-x-2">
                      <div class="flex items-start w-full gap-3">
                        <input
                          type="checkbox"
                          id="task_status"
                          :checked="subtask.is_completed"
                          :disabled="!this.getRoleAccess.patch_access"
                          @change="
                            toggleCompletionSubtaskStatus(
                              subtask.id,
                              subtask.task,
                              $event
                            )
                          "
                          class="w-4 mt-1"
                        />
                        <span
                          class="text-sm taskTitle text-wrap font-normal text-gray-600"
                          >{{ subtask.name }}</span
                        >
                      </div>
                      <div
                        v-if="subtask.end_date"
                        class="flex gap-6 text-xs justify-end w-[170px] text-gray-500"
                      >
                        <div
                          v-if="subtask.end_date"
                          class="flex items-center gap-1"
                        >
                          <span class="text-gray-800">Due@</span>
                          <span>{{
                            subtask.end_date
                              ? formatDateTime(subtask.end_date)
                              : "No Due"
                          }}</span>
                        </div>
                      </div>
                      <button
                        @click="previewSubtask(subtask.id)"
                        class="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            d="m14.707 12.707-4 4a1 1 0 0 1-1.414-1.414L12.586 12 9.293 8.707a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414z"
                            style="fill: #1c1b1e"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div
                    v-if="
                      this.getSelectedOrganization.organization_user_id ==
                      this.taskAllDetails[0]?.created_by
                    "
                    class="flex items-center gap-2 mt-4"
                  >
                    <button
                      @click="showSubTaskForm"
                      class="text-sm border px-2 py-1 border-blue-600 rounded-md font-semibold text-blue-600 hover:text-blue-800 cursor-pointer"
                    >
                      + Add subtask
                    </button>
                  </div>
                  <div
                    v-if="submitMessage"
                    :class="status === 200 ? 'text-green-500' : 'text-red-500'"
                  >
                    {{ message }}
                  </div>
                </div>
              </div>

              <div
                class="px-4 pt-2 w-full mt-auto bg-gray-100 border-t h-auto border-gray-300 pb-20"
              >
                <h1 class="text-gray-800 font-bold pb-2">Comments</h1>
                <hr />

                <div v-if="messages" class="pt-2 h-full flex flex-col gap-2">
                  <div
                    v-for="(message, index) in messages"
                    :key="index"
                    class="w-full group hover:shadow-md transition-all bg-white relative p-2 rounded-md"
                  >
                    <div v-if="message.attachment && !message.deleted">
                      <template
                        v-if="
                          imageList.includes(
                            getFileExtension(message.attachment)
                          )
                        "
                      >
                        <div class="relative">
                          <img :src="getSecureUrl(message.attachment)" alt="" />

                          <div
                            class="absolute bottom-2 right-2 flex items-center gap-2"
                          >
                            <div
                              class="p-2 bg-white rounded-md border border-gray-400"
                            >
                              <img
                                @click="
                                  toggleTaskImage(
                                    getSecureUrl(message.attachment)
                                  )
                                "
                                src="@/assets/imgprev.svg"
                                class="h-6 w-6 object-contain cursor-pointer"
                                alt=""
                              />
                            </div>
                            <div
                              class="p-2 bg-white rounded-md border border-gray-400"
                            >
                              <img
                                @click="
                                  downloadCommentFiles(
                                    getSecureUrl(message.attachment)
                                  )
                                "
                                src="@/assets/download_icon.svg"
                                alt=""
                                class="h-6 w-6 object-contain cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                      <template
                        v-else-if="
                          pdfFile.includes(getFileExtension(message.attachment))
                        "
                      >
                        <div
                          class="p-4 border rounded-md bg-gray-200 w-full relative"
                        >
                          <div
                            class="absolute bottom-2 right-2 flex items-center gap-2"
                          >
                            <div
                              class="p-2 bg-white rounded-md border border-gray-400"
                            >
                              <img
                                @click="
                                  downloadCommentFiles(
                                    getSecureUrl(message.attachment)
                                  )
                                "
                                src="@/assets/download_icon.svg"
                                alt=""
                                class="h-6 w-6 object-contain cursor-pointer"
                              />
                            </div>
                          </div>
                          <embed
                            :src="getSecureUrl(message.attachment)"
                            class="w-full min-h-[320px]"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <div
                          class="px-3 py-1 bg-white rounded-md border border-gray-400 flex justify-end inline-flex float-right items-center gap-3"
                        >
                          <img
                            @click="
                              downloadCommentFiles(
                                getSecureUrl(message.attachment)
                              )
                            "
                            src="@/assets/download_icon.svg"
                            alt=""
                            class="h-4 w-4 object-contain cursor-pointer"
                          />
                        </div>
                      </template>
                    </div>

                    <div class="flex my-3 gap-x-3 w-full">
                      <div class="w-10">
                        <span
                          class="h-9 text-sm w-9 rounded-full flex justify-center items-center"
                          :class="
                            index % 2 === 0 ? 'bg-green-300' : 'bg-blue-300'
                          "
                          >{{
                            message.team_member?.user?.display_name
                              ?.split(" ")
                              .slice(0, 2)
                              .map((name) => name.charAt(0))
                              .join("")
                              .toUpperCase()
                          }}
                        </span>
                      </div>

                      <div class="flex flex-col w-full">
                        <div class="flex w-full justify-between">
                          <div
                            class="text-gray-800 font-semibold capitalize text-sm"
                          >
                            {{ message.team_member?.user?.display_name }}
                          </div>
                          <div class="flex text-gray-600 gap-x-1 pt-1">
                            <span class="text-[11px] font-semibold">{{
                              messageDateTime(message.created_at).date
                            }}</span>
                            <span class="text-[11px]">{{
                              messageDateTime(message.created_at).time
                            }}</span>
                          </div>
                        </div>
                        <div
                          v-if="!message.deleted"
                          class="text-sm w-full mt-1"
                        >
                          {{ message.comment }}
                        </div>
                        <em
                          v-if="message.edited && !message.deleted"
                          class="text-xs text-gray-600 absolute bottom-1 right-2"
                          >edited</em
                        >
                        <div
                          v-if="message.deleted"
                          class="text-sm font-thin text-gray-500 w-full mt-1"
                        >
                          <em>This message was deleted</em>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        !message.deleted &&
                        message.team_member.user.id == this.commentId
                      "
                      @click="showOptions(message.id)"
                      class="absolute opacity-0 group-hover:opacity-100 rotate-45 group-hover:rotate-0 ease-in-out transition-all cursor-pointer right-2 top-1"
                    >
                      <svg
                        width="24"
                        fill="currentColor"
                        height="25"
                        class="text-gray-600"
                      >
                        <path
                          d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"
                        />
                      </svg>
                    </div>
                    <div
                      v-show="messageOptions === message.id"
                      class="absolute bg-white border rounded-md shadow-md right-0 -top-36 w-40 p-2 z-10"
                    >
                      <div
                        @click="editMessage(message)"
                        class="px-2 py-2 hover:bg-blue-100 hover:text-blue-500 cursor-pointer rounded-md"
                      >
                        Edit Message
                      </div>
                      <div
                        @click="deleteComment(message.id)"
                        class="px-2 py-2 hover:bg-red-100 hover:text-red-500 cursor-pointer rounded-md"
                      >
                        Delete Message
                      </div>
                      <div
                        @click="hideMessageOptions"
                        class="px-2 py-2 hover:bg-gray-100 cursor-pointer rounded-md"
                      >
                        Close
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else class="pt-5">No comments yet...</div>

                <form
                  v-if="!messageEdit"
                  @submit.prevent="submitMessage"
                  ref="commentForm"
                  class="bg-white p-2 fixed bottom-0 left-0 right-0"
                >
                  <p
                    v-if="attachFileName"
                    class="text-green-700 pt-2 line-clamp-1"
                  >
                    {{ attachFileName }}
                  </p>
                  <div class="flex items-center">
                    <div class="relative group">
                      <p
                        class="bg-gray-800 text-xs hidden group-hover:block transition-all px-3 py-1 text-white rounded-md absolute -top-10"
                      >
                        Attachments
                      </p>
                      <label for="addAttachments">
                        <svg
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          class="w-7 text-gray-500 hover:text-gray-700 transition-all cursor-pointer"
                        >
                          <path
                            d="M10 9 L10 24 C10 28 13 30 16 30 19 30 22 28 22 24 L22 6 C22 3 20 2 18 2 16 2 14 3 14 6 L14 23 C14 24 15 25 16 25 17 25 18 24 18 23 L18 9"
                          />
                        </svg>
                        <input
                          @change="
                            (el) => {
                              attachFileName = el.target.files[0].name;
                            }
                          "
                          type="file"
                          class="hidden"
                          name="add-attachments"
                          id="addAttachments"
                        />
                      </label>
                    </div>
                    <textarea
                      v-model="newMessage"
                      id="commentMessage"
                      placeholder="Add your comment"
                      class="block mx-2 h-10 my-2 p-2.5 w-full text-sm text-gray-900 bg-white rounded-md border border-gray-500 focus:ring-blue-500 focus:border-blue-500"
                    ></textarea>
                    <button
                      type="submit"
                      :disabled="!newMessage"
                      class="inline-flex justify-center p-2 text-blue-600 transition-all hover:text-blue-900 cursor-pointer disabled:text-gray-300"
                    >
                      <svg
                        class="w-9 h-6 rotate-90"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 20"
                      >
                        <path
                          d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
                <form
                  v-if="messageEdit"
                  @submit.prevent="updateComment"
                  ref="commentForm"
                  class="bg-white p-2 fixed bottom-0 left-0 right-0 shadow-md"
                >
                  <div class="flex items-center relative">
                    <button
                      @click="resetEditState"
                      class="absolute text-sm text-white -top-10 right-0 bg-red-500 rounded-md px-2 py-1"
                    >
                      Cancel edit
                    </button>
                    <textarea
                      v-model="editableMessage"
                      placeholder="Edit your comment"
                      class="block mx-2 h-10 my-2 p-2.5 w-full text-sm text-gray-900 bg-white rounded-md border border-gray-500 focus:ring-blue-500 focus:border-blue-500"
                    ></textarea>
                    <button
                      type="submit"
                      :disabled="!editableMessage"
                      class="inline-flex justify-center p-2 text-blue-600 transition-all hover:text-blue-900 cursor-pointer disabled:text-gray-300"
                    >
                      <svg
                        class="w-9 h-6 rotate-90"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 20"
                      >
                        <path
                          d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <!-- task edit form -->
            <div v-else class="text-start">
              <div
                class="border-b border-gray-400 flex items-center justify-between bg-blue-600 rounded-tl-lg text-white py-4 px-4"
              >
                <h5 class="mb-0 text-xl leading-normal">Update Task</h5>
                <svg
                  @click="editTask"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="bg-red-500 h-8 text-white font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>

              <div class="flex-grow overflow-y-auto p-4">
                <form
                  @submit.prevent="updateTask"
                  enctype="multipart/form-data"
                  class="flex flex-col gap-y-5"
                >
                  <div class="flex flex-col gap-1 relative">
                    <label
                      for="workspace_name"
                      class="text-slate-900 font-medium"
                      >Name</label
                    >

                    <input
                      type="text"
                      id="sub_task_name"
                      v-model="taskDetails.name"
                      class="rounded-lg bg-slate-100 text-gray-700 outline-none ring-transparent px-4 py-2 w-full"
                      :class="[
                        taskDetails.name.length >= 100
                          ? 'border-2 border-red-500'
                          : 'border-2 border-gray-200',
                      ]"
                      placeholder="Enter subtask name"
                      required
                    />
                    <span
                      v-if="taskDetails.name.length >= 100"
                      class="text-xs absolute bottom-12 mt-1 right-0 text-red-500"
                      >Task name should not exceed more than 100 characters
                      !!</span
                    >
                  </div>

                  <div class="flex flex-col gap-1">
                    <label for="desc" class="text-slate-900 font-medium"
                      >Description</label
                    >
                    <textarea
                      id="desc"
                      v-model="taskDetails.description"
                      class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
                      rows="4"
                      required
                    ></textarea>
                  </div>

                  <div class="flex flex-col gap-1">
                    <label for="start_date" class="text-slate-900 font-medium"
                      >Start Date</label
                    >
                    <input
                      type="date"
                      id="start_date"
                      v-model="taskDetails.start_date"
                      class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
                    />
                  </div>

                  <div class="flex flex-col gap-1">
                    <label for="end_date" class="text-slate-900 font-medium"
                      >End Date</label
                    >
                    <input
                      type="date"
                      id="end_date"
                      v-model="taskDetails.end_date"
                      class="text-slate-700 bg-slate-100 rounded-md border border-gray-300 py-2 w-full text-start px-2 focus:ring-2 focus:ring-[#76bdeb]"
                    />
                  </div>

                  <div class="flex flex-col gap-1">
                    <label
                      for="workspace_img"
                      class="text-slate-900 font-medium"
                      >Image</label
                    >
                    <input
                      type="file"
                      name="workspace_img"
                      @change="TaskfileName"
                      class="rounded-lg border text-gray-700 bg-gray-100 h-10 text-sm"
                      accept="image/*"
                    />
                  </div>

                  <div class="text-center">
                    <button
                      type="submit"
                      class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-5 py-2 rounded-lg text-sm transition-transform hover:bg-[#76bdeb] shadow-lg"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>

              <h1
                class="flex justify-center"
                :class="
                  messageType === 'success' ? 'text-green-600' : 'text-red-600'
                "
              >
                {{ message }}
              </h1>
            </div>
          </div>
        </div>
        <div
          :class="[
            'fixed right-0 top-0 z-30 h-full bg-white shadow-xl transition-transform duration-300 ease-in-out',
            is_subtask_popup ? 'translate-x-0' : 'translate-x-full',
            'w-[85vw] md:w-[480px]',
          ]"
        >
          <div
            class="flex items-center justify-between bg-blue-600 rounded-l-lg text-white py-4 px-4"
          >
            <h5 class="mb-0 font-semibold text-medium leading-normal" id="">
              Create New Subtask
            </h5>

            <svg
              @click="closeSubTaskForm"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="bg-red-500 h-7 text-white absolute right-5 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div class="flex-grow overflow-y-auto p-4 text-[.9rem]">
            <form
              ref="subtask_post"
              @submit.prevent="addSubTask"
              class="flex flex-col gap-5 bg-white rounded-2xl w-full max-w-lg mx-auto relative"
            >
              <div class="flex flex-col relative gap-2">
                <label
                  for="sub_task_name"
                  class="text-md font-medium text-start text-gray-900"
                  >Subtask Name<span class="text-red-500">*</span></label
                >
                <input
                  type="text"
                  id="sub_task_name"
                  v-model="subtask.name"
                  class="rounded-lg bg-slate-100 text-gray-700 outline-none ring-transparent px-4 py-2 w-full"
                  :class="[
                    subtask.name.length >= 100
                      ? 'border-2 border-red-500'
                      : 'border-2 border-gray-200',
                  ]"
                  placeholder="Enter subtask name"
                  required
                />
                <span
                  v-if="subtask.name.length >= 100"
                  class="text-xs absolute top-16 mt-1.5 right-0 text-red-500"
                  >subtask name should not exceed more than 100 characters
                  !!</span
                >
              </div>

              <div class="flex flex-col gap-2">
                <label
                  for="start_date"
                  class="text-md font-medium text-start text-gray-900"
                  >Start Date</label
                >
                <input
                  type="date"
                  id="start_date"
                  v-model="subtask.start_date"
                  class="rounded-lg border border-gray-300 text-gray-700 px-4 py-2 bg-slate-100 focus:ring w-full"
                />
              </div>

              <div class="flex flex-col gap-2">
                <label
                  for="end_date"
                  class="text-md font-medium text-start text-gray-900"
                  >End Date</label
                >
                <input
                  type="date"
                  id="end_date"
                  v-model="subtask.end_date"
                  class="rounded-lg border border-gray-300 text-gray-700 px-4 py-2 bg-slate-100 w-full"
                />
              </div>

              <div class="flex flex-col gap-2">
                <label
                  for="document"
                  class="text-md font-medium text-start text-gray-900"
                  >Upload Document</label
                >
                <input
                  type="file"
                  id="document"
                  @change="subTaskfileName"
                  class="rounded-lg border border-gray-300 bg-slate-100 text-gray-700 h-10 w-full"
                />
              </div>

              <div class="text-center pt-4">
                <button
                  type="submit"
                  class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-5 py-2 rounded-lg font-medium shadow-md hover:bg-[#76bdeb] hover:text-white transition-all w-full sm:w-auto"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

          <div
            v-if="subtaskmessage"
            class="text-green-600 p-2 flex justify-center"
          >
            {{ subtaskmessage }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="deleteImagePopup"
      class="fixed z-[999] top-0 left-0 flex justify-center items-center right-0 bottom-0 min-h-screen h-full overflow-auto bg-black bg-opacity-10"
    >
      <div class="bg-white p-7 relative rounded-md">
        <svg
          @click="deleteTaskImage"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="bg-red-500 h-7 text-white absolute right-0 top-0 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
        <span class="text-base text-gray-700"
          >Are you sure you want to delete this image ?</span
        >
        <div class="flex gap-x-3 mt-6 items-center justify-center">
          <button
            @click="deleteTaskDocument"
            class="px-4 py-2 rounded-md text-white transition-all bg-red-500 hover:bg-red-800"
          >
            Delete
          </button>
          <button
            @click="deleteTaskImage"
            class="px-4 py-2 rounded-md text-white transition-all bg-blue-500 hover:bg-blue-800"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
    <SubtaskDetails
      v-if="previewSubtaskDetails"
      :previewSubtaskDetails="previewSubtaskDetails"
      @closeSubTaskCanvas="this.previewSubtaskDetails = false"
      :subTaskId="subTaskId"
    />
    <TaskImagePreview
      v-if="taskImgPreview"
      :preview="toggleTaskImage"
      :prev_image="prev_image"
      @closeFileView="taskImgPreview = false"
    />
  </div>
</template>

<script>
import SubtaskDetails from "@/components/Task/SubtaskDetails.vue";
import TaskImagePreview from "@/components/Task/TaskImagePreview.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "TaskDetails",
  components: { TaskImagePreview, SubtaskDetails },
  props: {
    showtaskDetails: { type: Object, required: true },
    slug: String,
    task_id: {
      type: Number,
      required: true,
    },
    projectId: Number,
    refreshWorkspace: Function,
    flagList: Array,
    memberList: Array,
  },
  data() {
    return {
      taskCompletedStatus: "",
      previewSubtaskDetails: false,
      commentId: "",
      messageEdit: false,
      editableMessage: "",
      newEditedMessageId: null,
      messageOptions: null,
      files: [],
      taskImgPreview: false,
      flagMsg: false,
      tmsg: false,
      newFlag: "",
      flagId: "",
      task: {
        is_completed: false,
      },
      subtaskcheck: {
        is_completed: false,
      },
      Task_details: false,
      newMessage: "",
      member_id: "",
      messages: [],
      userName: "",
      validationError: null,
      isSubmitting: false,
      teamMember: 0,
      taskId: "",
      user_member_id: 0,
      is_subtask_popup: false,
      taskedit: false,
      taskDetails: {
        name: this.showtaskDetails.name || "",
        description: this.showtaskDetails.description || "",
        start_date: this.formatDate(this.showtaskDetails.start_date),
        end_date: this.formatDate(this.showtaskDetails.end_date),
        document: this.showtaskDetails.document || null,
      },
      subtaskmessage: false,
      subtask: {
        name: "",
        is_completed: false,
        order: 0,
        start_date: "",
        end_date: "",
        document: null,
      },
      assignedTo: "",
      subtaskdetails: [],
      message: null,
      taskName: "",
      newTaskDetails: [],
      newSubTask: [],
      subTaskId: "",
      taskDescription: "",
      taskImage: "",
      subTaskImage: "",
      newDoc: "",
      prev_image: "",
      imageList: ["jpeg", "png", "gif", "jpg", "webm", "webp", "svg", "avif"],
      pdfFile: ["pdf"],
      attachFileName: "",
      deleteImagePopup: false,
      taskAllDetails: [],
    };
  },

  computed: {
    ...mapGetters([
      "getUser",
      "getOrgUser",
      "getSelectedOrganization",
      "getAccessToken",
      "getRoleAccess",
    ]),
  },

  mounted() {
    this.NewtaskList();
    this.fetchComments();
    this.getSubtaskList();
    this.getUserName();
  },
  methods: {
    deleteTaskImage() {
      this.deleteImagePopup = !this.deleteImagePopup;
    },
    async deleteTaskDocument() {
      if (this.getRoleAccess.patch_access) {
        try {
          const response = await axios.patch(
            `${process.env.VUE_APP_API}/task/update-destroy/${this.task_id}/`,
            {
              document: "",
            },
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            this.NewtaskList();
            this.deleteTaskImage();
            this.$emit("updateTask");
          }
        } catch (error) {
          console.error("Error deleting task image:", error);
        }
      } else {
        alert("You dont have access to delete image");
      }
    },
    previewSubtask(subtaskId) {
      this.previewSubtaskDetails = true;
      this.subTaskId = subtaskId;
    },
    editMessage(message) {
      this.messageEdit = true;
      this.newEditedMessageId = message.id;
      this.editableMessage = message.comment;
      this.hideMessageOptions();
    },
    async updateComment() {
      if (this.getRoleAccess.patch_access) {
        try {
          if (!this.newEditedMessageId || !this.editableMessage) return;

          const response = await axios.patch(
            `${process.env.VUE_APP_API}/comment/update-destroy/${this.newEditedMessageId}/`,
            {
              comment: this.editableMessage,
              edited: true,
            },
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            this.fetchComments();
            this.resetEditState();
          }
        } catch (error) {
          console.error("Error updating comment:", error);
        }
      } else {
        alert("You dont have access to update comment");
      }
    },
    resetEditState() {
      this.messageEdit = false;
      this.editableMessage = "";
      this.newEditedMessageId = null;
    },
    hideMessageOptions() {
      this.messageOptions = null;
    },
    async deleteComment(cmt_id) {
      if (this.getRoleAccess.patch_access) {
        try {
          const response = await axios.patch(
            `${process.env.VUE_APP_API}/comment/update-destroy/${cmt_id}/`,
            {
              deleted: true,
            },
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            this.fetchComments();
            this.hideMessageOptions();
          }
        } catch (error) {
          console.error("Error deleting comment:", error);
        }
      } else {
        alert("You dont have access to delete a comment");
      }
    },
    showOptions(id) {
      this.messageOptions = id;
    },

    async archiveCard() {
      if (this.getRoleAccess.patch_access) {
        try {
          const formData = new FormData();
          formData.append("status", false);

          const response = await axios.patch(
            `${process.env.VUE_APP_API}/task/update-destroy/${this.task_id}/`,
            formData,

            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.status === 200) {
            this.$emit("updateTask");
            this.$emit("closeCanvasDetails");
          }
        } catch (error) {
          console.error("Error archiving task:", error);
        }
      } else {
        alert("You dont have access to archive task");
      }
    },
    async downloadCommentFiles(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = url.split("/").pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("Download failed:", error);
      }
    },
    getFileNameFromUrl(url) {
      return url.split("/").pop();
    },
    getFileExtension(url) {
      let fileName = url;
      let extension = fileName.substring(fileName.lastIndexOf(".") + 1);
      return extension;
    },
    toggleTaskImage(img) {
      this.prev_image = img;
      this.taskImgPreview = !this.taskImgPreview;
    },

    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      return d.toLocaleDateString("en-CA");
    },
    async flagUpdate() {
      if (this.getRoleAccess.patch_access) {
        const resp = await axios.patch(
          `${process.env.VUE_APP_API}/task/update-destroy/${this.task_id}/`,
          {
            flag: this.flagId,
          },
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (resp.status == 200) {
          this.flagMsg = "Priority updated successfully";
          this.status = 200;
          this.flagId = "";
          setTimeout(() => {
            this.flagMsg = null;
            this.$emit("updateTask");
          }, 2000);
          this.NewtaskList();
        }
      } else {
        alert("You dont have access to update flag");
      }
    },
    async NewtaskList() {
      this.Task_details = true;
      await axios
        .get(`${process.env.VUE_APP_API}/task/list/?id=${this.task_id}`, {
          params: {
            organization_accesskey_id:
              this.getSelectedOrganization.organization_id,
          },
          headers: {
            Authorization: `Bearer ${this.getAccessToken}`,
          },
        })
        .then((resp) => {
          if (resp.status === 200) {
            this.assignedTo =
              resp.data?.data[0]?.assigned_to?.user.display_name;
            this.taskAllDetails = resp.data?.data;
            this.taskName = resp.data?.data[0]?.name;
            this.newTaskDetails = resp.data.data;
            this.newSubTask = resp.data.data;
            this.taskDescription = resp.data.data[0]?.description;
            this.newFlag = resp.data.data[0]?.flag;
            this.newDoc = resp.data.data[0]?.document;
            this.newDue = resp.data.data[0]?.end_date;
            this.taskCompletedStatus = resp.data.data[0]?.is_completed;
          } else {
            this.assignedTo = "";
            console.error("Error while adding task:", resp.data.message);
          }
        });
    },
    async toggleCompletionSubtaskStatus(subTaskId, taskId, el) {
      if (this.getRoleAccess.patch_access) {
        try {
          const response = await axios.patch(
            `${process.env.VUE_APP_API}/sub-task/update-destroy/${subTaskId}/`,
            {
              is_completed: el.target.checked,
              task: taskId,
            },
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          );

          if (response.status === 200) {
            this.$emit("updateTask");
            console.log("Task status updated:", response.data);
          } else {
            console.error("Failed", response.data.message);
          }
        } catch (error) {
          console.error("Error updating:", error);
          this.errorMessage = "Failed";
        }
      } else {
        alert("You dont have the access to complete a subtask");
      }
    },
    async getUserName() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/team-member/list/?project__id=${this.projectId}&organization_user_id=${this.getOrgUser}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (response.status === 200) {
          this.userName =
            response.data.data[0]?.organization_user.user.display_name || "";
          this.commentId =
            response.data.data[0]?.organization_user.user.id || "";
          this.user_member_id = response.data.data[0]?.id;
          console.log("usermember id", response.data.data[0]?.id);
        } else {
          this.userName = "";
          this.user_member_id = "";
          console.error(
            "Failed to retrieve team members:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },

    async toggleCompletionStatus(el) {
      if (this.getRoleAccess.patch_access) {
        try {
          const response = await axios.patch(
            `${process.env.VUE_APP_API}/task/update-destroy/${this.task_id}/`,
            {
              is_completed: el.target.checked,
            },
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          );

          if (response.status === 200) {
            setTimeout(() => {
              this.$emit("updateTask");
            }, 1000);
          } else {
            console.error("Failed", response.data.message);
          }
        } catch (error) {
          console.error("Error updating:", error);
          this.errorMessage = "Failed";
        }
      } else {
        alert("You dont have access to complete the task");
      }
    },
    async assignTo() {
      if (this.getRoleAccess.patch_access) {
        await axios
          .patch(
            `${process.env.VUE_APP_API}/task/update-destroy/${this.task_id}/`,
            {
              assigned_to: this.member_id,
            },
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          )
          .then((resp) => {
            if (resp.status == 200) {
              this.tmsg = "Member assigned successfully";
              this.status = 200;
              setTimeout(() => {
                this.tmsg = null;
                this.showtaskDetails;
                this.$emit("updateTask");
              }, 2000);
              this.NewtaskList();
            } else {
              console.error("Error while adding task:", resp.data.message);
            }
          });
      } else {
        alert("You dont have access to assign");
      }
    },

    getSecureUrl(url) {
      if (url.startsWith("http:")) {
        return url.replace("http:", "https:");
      }
      return url;
    },
    formatDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month}`;
    },
    messageDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("default", { month: "short" });
      const time = date.toLocaleString("default", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      return {
        date: `${day} ${month}`,
        time: time,
      };
    },
    async fetchComments() {
      try {
        this.isLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_API}/comment/list/?task__id=${this.task_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (response.status === 200) {
          this.messages = response.data.data;
          console.log("messages response ::", response);
        } else {
          console.error("Failed to fetch comments:", response.data.message);
          this.errorMessage = "Please try again.";
        }
      } catch (error) {
        console.error("API Error:", error);
        this.errorMessage = "Please check your connection.";
      } finally {
        this.isLoading = false;
      }
    },

    async submitMessage(el) {
      if (this.getRoleAccess.post_access) {
        this.isSubmitting = true;
        let attachmentFile = el.target.elements.addAttachments.files;
        var messageData;
        if (attachmentFile.length > 0) {
          messageData = new FormData();

          messageData.append("attachment", attachmentFile[0]);
          messageData.append(
            "comment",
            el.target.elements.commentMessage.value
          );
          messageData.append("task", this.task_id);
          messageData.append("team_member", this.user_member_id);
        } else {
          messageData = {
            comment: this.newMessage,
            task: this.task_id,
            team_member: this.user_member_id,
          };
        }

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API}/comment/create/`,
            messageData,
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          );
          if (response.status === 200) {
            this.fetchComments();
            this.newMessage = "";
          }
        } catch (error) {
          console.error("Error submitting message:", error);
        } finally {
          this.$refs.commentForm.reset();
          this.isSubmitting = false;
          this.attachFileName = "";
        }
      } else {
        alert("You dont have access to send a message");
      }
    },

    TaskfileName(event) {
      this.taskImage = event.target.files[0];
    },
    async updateTask() {
      if (this.getRoleAccess.post_access) {
        let formData = new FormData();
        formData.append("name", this.taskDetails.name);
        formData.append("description", this.taskDetails.description);
        formData.append("start_date", this.taskDetails.start_date);
        formData.append("end_date", this.taskDetails.end_date);
        formData.append("status", true);
        if (this.taskImage) {
          formData.append("document", this.taskImage);
        }
        try {
          const resp = await axios.patch(
            `${process.env.VUE_APP_API}/task/update-destroy/${this.task_id}/`,
            formData,

            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (resp.status === 200) {
            this.message = resp.data.message;
            this.messageType = "success";

            setTimeout(() => {
              this.message = "";
              this.$emit("updateTask");
              this.editTask();
            }, 2000);
            this.NewtaskList();
          }
        } catch (error) {
          console.error("Update failed:", error);

          this.message = error.response?.data?.message || "An error occurred";
          this.messageType = "error";
          this.message = "";

          setTimeout(() => {}, 2000);
        }
      } else {
        alert("You dont have access to edit task");
      }
    },
    subTaskfileName(event) {
      this.subTaskImage = event.target.files[0];
    },
    async addSubTask() {
      if (this.getRoleAccess.post_access) {
        try {
          const formData = new FormData();
          formData.append("name", this.subtask.name);
          formData.append("is_completed", this.subtask.is_completed);
          formData.append("order", this.subtask.order);
          formData.append("start_date", this.subtask.start_date);
          formData.append("end_date", this.subtask.end_date);
          formData.append("task", this.task_id);

          if (this.subTaskImage) {
            formData.append("document", this.subTaskImage);
          }

          const response = await axios.post(
            `${process.env.VUE_APP_API}/sub-task/create/`,
            formData,

            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status == 200) {
            this.subtaskmessage = response.data.message;
            this.status = 200;
            this.subTaskImage = "";
            this.getSubtaskList();
            setTimeout(() => {
              this.subtaskmessage = null;
              this.$emit("updateTask");
              this.closeSubTaskForm();
            }, 3000);
            {
              this.subtask = {
                name: "",
                is_completed: false,
                order: 0,
                start_date: "",
                end_date: "",
                document: null,
              };
            }
          } else {
            this.subtaskmessage = response.data.message;
            setTimeout(() => {
              this.subtaskmessage = null;
            }, 2000);
          }
        } catch (error) {
          this.subtaskmessage = error.response?.data?.message;
          console.error("Error adding subtask:", error);

          setTimeout(() => {
            this.subtaskmessage = null;
          }, 2000);
        }
      } else {
        alert("You dont have access to add a subtask");
      }
    },
    async getSubtaskList() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/sub-task/list/?task_id=${this.task_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (response.status === 200) {
          this.subtaskdetails = response.data.data;
        } else {
          this.subtaskdetails = [];
          console.error("Failed to retrieve subtasks:", response.data.message);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },

    editTask() {
      this.taskedit = !this.taskedit;
    },

    closeTaskCanvas() {
      this.Task_details = false;
      setTimeout(() => {
        this.$emit("closeCanvasDetails");
      }, 600);
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      this.taskDetails.document = URL.createObjectURL(file);
    },

    showSubTaskForm() {
      this.is_subtask_popup = true;
    },

    closeSubTaskForm() {
      this.is_subtask_popup = false;
    },
  },
};
</script>
<style scoped></style>
