<template>
  <div v-if="createRole" class="fixed inset-0 z-[999] bg-black bg-opacity-10">
    <div
      class="fixed top-0 right-0 bg-white w-full max-w-sm h-full shadow-lg transform transition-transform duration-300"
    >
      <form
        @submit.prevent="saveRole"
        class="flex flex-col gap-y-4 relative h-full"
      >
        <div
          class="flex items-center justify-between bg-blue-600 text-white py-4 px-4 rounded-t-lg"
        >
          <h1 class="text-lg font-semibold">Add Role</h1>
          <svg
            @click="closeAddRole"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="bg-red-500 h-8 text-white font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div class="flex-grow overflow-y-auto px-4 py-2 space-y-4 text-start">
          <div>
            <label for="category" class="block text-gray-700 text-sm mb-2">
              Category <b class="text-red-500">*</b>
            </label>
            <select
              v-model="category"
              required
              id="category"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
            >
              <option value="">Select Category</option>
              <option v-for="cat in categoryList" :key="cat.id" :value="cat.id">
                {{ cat.name }}
              </option>
            </select>
          </div>

          <div>
            <label for="name" class="block text-gray-700 text-sm mb-2">
              Name <b class="text-red-500">*</b>
            </label>
            <input
              v-model="name"
              id="name"
              type="text"
              required
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
            />
          </div>
          <div>
            <label for="shortname" class="block text-gray-700 text-sm mb-2">
              Short Name <b class="text-red-500">*</b>
            </label>
            <input
              v-model="shortName"
              id="shortName"
              type="text"
              required
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
            />
          </div>

          <div>
            <label for="level" class="block text-gray-700 text-sm mb-2">
              Level
            </label>
            <select
              required
              v-model="level"
              id="level"
              class="block w-full text-gray-700 bg-slate-100 rounded-md border border-gray-300 py-2 px-3 focus:ring-2 focus:ring-blue-400 focus:outline-none text-sm"
            >
              <option value="">Select Level</option>
              <option value="1">Level 1</option>
              <option value="2">Level 2</option>
            </select>
          </div>
        </div>

        <div class="py-4 text-center">
          <button
            type="submit"
            class="bg-blue-600 text-white w-36 py-2 rounded-lg text-sm font-medium transition-all hover:bg-blue-700 shadow-lg"
          >
            Submit
          </button>
        </div>
        <div
          v-if="message"
          :class="status === 200 ? 'text-green-500' : 'text-red-500'"
        >
          {{ message }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  props: {
    showRoleCreate: {
      type: Boolean,
    },
  },

  data() {
    return {
      createRole: this.showRoleCreate,
      category: "",
      categoryList: [],
      name: "",
      shortName: "",
      level: "",
      message: null,
      status: null,
    };
  },
  created() {
    this.fetchCategories();
  },
  computed: {
    ...mapGetters([
      "getSelectedOrganization",
      "getAccessToken",
      "getUser",
      "getRoleAccess",
    ]),
  },

  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/role-category/list/`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        this.categoryList = response.data.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async saveRole() {
      if (this.getRoleAccess.post_access) {
        try {
          this.loading = true;
          const response = await axios.post(
            `${process.env.VUE_APP_API}/user-role/create/`,
            {
              name: this.name,
              short_name: this.shortName,
              organization: this.getSelectedOrganization.organization_id,
              role_category: this.category,
              level: this.level,
            },
            {
              params: {
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          );

          if (response.data.success) {
            this.message = response.data.message;
            this.status = 200;
            setTimeout(() => {
              this.message = null;
              this.closeAddRole();
            }, 2000);
          }
        } catch (error) {
          this.message =
            error.response?.data?.message || "An unexpected error occurred.";
          setTimeout(() => {
            this.message = null;
            this.closeAddRole();
          }, 5000);
        } finally {
          this.loading = false;
        }
      } else {
        alert("You dont have access to create a role");
      }
    },

    closeAddRole() {
      this.resetForm();
      this.$emit("close-form");
    },

    resetForm() {
      this.name = "";
      this.category = "";
      this.role = "";
      this.level = "";
      this.errorMessage = "";
      this.successMessage = "";
    },
  },
};
</script>
