<template>
  <div class="flex gap-4 mx-[10px]">
    <SideNav
      v-if="workspace_details.length > 0"
      class="mt-[20px]"
      :organization_list="organization_list"
      @workspaceCallBack="callBackWorkspace"
      @workspaceList="workspaceDropdown"
      :workspaceDetails="workspace_details"
    />
    <div class="min-h-[100vh] h-full mt-[20px] w-full">
      <div class="flex flex-col gap-2 w-full">
        <TopNav />
        <div class="w-full">
          <div class="flex flex-col gap-6 justify-center items-center w-full">
            <div class="w-full bg-white rounded-xl shadow-lg py-2">
              <div class="relative">
                <p class="text-start text-lg text-gray-700 p-5">
                  Project Details
                </p>
                <div class="absolute top-4 right-4">
                  <button
                    class="bg-soft-primary border border-primary text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all shadow-lg"
                    @click="toggleProjectOffcanvas"
                  >
                    Add Project
                  </button>
                </div>
              </div>
              <table class="overflow-hidden rounded-b-xl w-full 2xl:w-full">
                <thead class="border-b border-gray-200">
                  <tr class="text-left text-sm">
                    <th class="px-6 py-2 text-gray-700">Project Name</th>
                    <th class="px-6 py-2 text-gray-700">Workspace</th>
                    <th class="px-6 py-2 text-gray-700">Team</th>
                    <th class="px-6 py-2 text-gray-700">Description</th>
                    <th class="px-6 py-2 text-gray-700 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="new_project_List.length > 0" class="text-sm">
                  <tr
                    v-for="project in new_project_List"
                    :key="project.id"
                    class="hover:bg-gray-100 transition-colors text-start"
                  >
                    <td class="px-6 py-1.5 text-[#76bdeb]">
                      {{ project.name }}
                    </td>
                    <td class="px-10 py-1.5 text-start">
                      {{ project.workspace }}
                    </td>
                    <td class="px-10 py-1.5 text-start">
                      {{ project.team ? project.team : "Not assigned" }}
                    </td>
                    <td class="px-10 py-1.5 text-start">
                      {{ project.description }}
                    </td>
                    <td class="px-10 py-1.5 text-center">
                      <router-link
                        :to="{
                          name: 'workspace',
                          params: { slug: project.slug },
                        }"
                        class="bg-white text-gray-700 border py-0.5 text-sm hover:border-sky-600 rounded-lg inline-block px-4"
                      >
                        View
                      </router-link>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5" class="py-4 text-sm">
                      No Projects Available !!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Project Popup -->
      <div
        :class="[
          'fixed right-0 top-0 z-40  h-full bg-white shadow-xl transition-transform duration-300 ease-in-out p-10 ',
          isProjectOffcanvasOpen ? 'translate-x-0' : 'translate-x-full',
        ]"
      >
        <div class="flex flex-col gap-2 h-[80%] rounded-md text-left">
          <h1 class="text-xl relative">
            Add New Project
            <button
              @click="toggleProjectOffcanvas"
              type="button"
              class="absolute top-0 right-0 text-base"
              aria-label="Close"
            >
              X
            </button>
          </h1>
          <form
            ref="project_form"
            @submit.prevent="projectPost"
            class="flex flex-col gap-4"
          >
            <div class="flex flex-col gap-1">
              <label for="name"
                >Project name <span class="text-red-500">*</span></label
              >
              <input
                id="project_name"
                type="text"
                required
                class="border border-gray-300 p-1 rounded-md"
              />
            </div>

            <div class="flex flex-col gap-1">
              <label for="background_image">Background Image</label>
              <input id="bg_image" type="file" class="file-upload" />
            </div>
            <div class="flex flex-col gap-1 items-start">
              <label for="description">Description</label>
              <textarea
                id="desc"
                cols="30"
                rows="4"
                placeholder="Describe your workspace..."
                class="rounded-md w-full text-gray-800 px-2 pt-0.5 border focus:outline-none bo focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
            <div class="flex justify-center">
              <button
                type="submit"
                class="bg-soft-primary border border-primary text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-all hover:bg-primary shadow-lg"
              >
                Add Project
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TopNav from "@/components/TopNav.vue";
import SideNav from "@/components/SideNav.vue";

export default {
  name: "WorkspaceView",
  components: {
    TopNav,
    SideNav,
  },
  data() {
    return {
      // isOffcanvasOpen: false,
      isProjectOffcanvasOpen: false,
      organization_list: [],
      new_project_List: [],
      workspace_details: [],
      current_workspace: null,
    };
  },

  async created() {
    await this.workspaceList();
    this.organizationList();
  },

  mounted() {},
  methods: {
    callBackWorkspace(id) {
      console.log("call back function for workspace");
      this.workspace_details = [];
      axios
        .get(`${process.env.VUE_APP_API}/project/list/`)
        .then((response) => {
          if (response.data.status == 200) {
            this.workspace_details = response.data.data;
            this.selectWorkspace(id);
          }
        })
        .catch((error) => {
          console.error("API call failed:", error);
        });
    },
    async workspaceList() {
      await axios
        .get(`${process.env.VUE_APP_API}/workspace/list/`)
        .then((response) => {
          if (response.data.status == 200) {
            this.workspace_details = response.data.data;
            this.selectWorkspace(this.workspace_details[0].id);
          }
        })
        .catch((error) => {
          console.error("API call failed:", error);
        });
    },
    async selectWorkspace(id) {
      this.current_workspace = id;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/project/list/?workspace=${id}`
        );
        if (response.data.status === 200) {
          console.log(response.data);
          if (response.data.data.length > 0) {
            this.new_project_List = response.data.data;
          }
        } else {
          this.new_project_List = [];
          console.error(`Error: Received status ${response.status}`);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },

    async projectPost(el) {
      const formProjectData = new FormData();
      formProjectData.append("name", el.target.elements.project_name.value);
      formProjectData.append("workspace", this.current_workspace);
      formProjectData.append("description", el.target.elements.desc.value);
      formProjectData.append(
        "backround_image",
        el.target.elements.bg_image.files[0]
      );

      await axios
        .post(`${process.env.VUE_APP_API}/project/create/`, formProjectData)
        .then((response) => {
          console.log(response);
          this.$refs.project_form.reset();
          this.toggleProjectOffcanvas();
          this.selectWorkspace(this.current_workspace);
        })
        .catch((error) => {
          console.error("Error creating Project:", error);
          if (error.response) {
            console.error("Response data:", error.response.data);
          }
        });
    },

    async organizationList() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/organization/list/`
        );
        this.organization_list = response.data.data;
      } catch (error) {
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      }
    },
    workspaceDropdown(id) {
      this.selectWorkspace(id);
    },

    toggleProjectOffcanvas() {
      this.isProjectOffcanvasOpen = !this.isProjectOffcanvasOpen;
    },
  },
};
</script>
<style scoped></style>
