<template>
  <div class="flex gap-4">
    <LeftNav class="m-0" />
    <div class="min-h-[100vh] h-full w-full">
      <div class="flex flex-col gap-2 w-full">
        <TopNav />
        <div class="w-full h-[90vh] pb-3 overflow-auto">
          <div class="py-3">
            <div class="bg-white px-6 pb-6 rounded-lg shadow-lg">
              <div class="flex items-center justify-between py-3">
                <h1 class="text-lg font-normal text-slate-700">
                  Overall Task List ({{ pageTotalcount }})
                </h1>
                <div
                  class="flex items-center sm:space-x-4 space-y-4 sm:space-y-0"
                >
                  <button
                    @click="clearFilters"
                    v-if="
                      this.userId !== '' ||
                      this.projectId !== '' ||
                      this.created_date !== ''
                    "
                    class="bg-red-500 text-white px-3 py-1.5 text-sm rounded-lg"
                  >
                    Clear filter
                  </button>
                  <div class="">
                    <select
                      id="member-select"
                      v-model="userId"
                      class="w-full py-2 px-3 border text-sm border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none"
                      @change="getMyTasks()"
                    >
                      <option value="" selected default>Assigned to</option>
                      <option
                        v-for="user in usersList"
                        :key="user.id"
                        :value="user.id"
                      >
                        {{ user.user.display_name }}
                      </option>
                    </select>
                  </div>

                  <div class="relative">
                    <select
                      v-if="this.userId"
                      id="status-select"
                      @change="getMyTasks()"
                      v-model="projectId"
                      class="w-full py-2 px-3 border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none text-sm"
                    >
                      <option value="" selected default>All Projects</option>
                      <option
                        v-for="project in projectsList"
                        :key="project.project.id"
                        :value="project.project.id"
                      >
                        {{ project.project.name }}
                      </option>
                    </select>
                    <select
                      v-else
                      id="status-select"
                      @change="getMyTasks()"
                      v-model="projectId"
                      class="w-full py-2 px-3 border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none text-sm"
                    >
                      <option value="" selected default>All Projects</option>
                      <option
                        v-for="project in projectsAllList"
                        :key="project.id"
                        :value="project.id"
                      >
                        {{ project.name }}
                      </option>
                    </select>
                  </div>
                  <div class="relative w-36">
                    <input
                      v-model="created_date"
                      @change="getMyTasks()"
                      :max="todayDate"
                      type="date"
                      class="w-full py-1.5 px-3 border text-sm border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none"
                    />
                  </div>
                </div>
              </div>
              <div class="min-h-[700px] max-h-auto">
                <table
                  v-if="overAllTaskList"
                  class="min-w-full border-collapse"
                >
                  <!-- Table Header -->
                  <thead>
                    <tr>
                      <th
                        class="px-3 w-5 border border-gray-300 py-3 text-xs font-bold uppercase text-start"
                      >
                        S.No
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        {{
                          this.getSelectedOrganization.organization_special_role
                            ? "Task Details"
                            : "Task Name"
                        }}
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        {{
                          this.getSelectedOrganization.organization_special_role
                            ? "Sub-Task"
                            : "Task Label"
                        }}
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        {{
                          this.getSelectedOrganization.organization_special_role
                            ? "Task Category"
                            : "Project Name"
                        }}
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs text-wrap font-bold uppercase text-start"
                      >
                        Assigned to
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs font-bold uppercase text-start"
                      >
                        Start date
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs font-bold uppercase text-start"
                      >
                        End date
                      </th>
                      <th
                        scope="col"
                        class="px-3 tracking-wider border border-gray-300 py-3 text-xs font-bold uppercase text-start"
                      >
                        Created date
                      </th>
                    </tr>
                  </thead>
                  <!-- Table Body -->
                  <tbody class="bg-white overflow-auto">
                    <template v-if="loading">
                      <tr class="h-[63vh]">
                        <td colspan="7" class="text-center py-10 align-middle">
                          <div class="relative inline-block">
                            <div
                              class="w-14 h-14 border-blue-200 border-2 rounded-full"
                            ></div>
                            <div
                              class="w-14 h-14 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"
                            ></div>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <template v-if="overAllTaskList != ''">
                        <tr
                          v-for="(task, index) in overAllTaskList"
                          :key="task.id"
                          class="hover:bg-gray-200 text-sm"
                        >
                          <td
                            class="px-3 py-1 w-5 whitespace-nowrap text-wrap text-start border border-gray-300"
                          >
                            {{ index + 1 + (currentPage - 1) * itemsPerPage }}
                          </td>
                          <td
                            class="px-3 py-1 whitespace-nowrap text-wrap w-60 text-start border border-gray-300"
                          >
                            {{ task.name }}
                          </td>
                          <td
                            class="px-3 py-1 whitespace-nowrap text-wrap w-60 text-start border border-gray-300"
                          >
                            {{ task.label ? task.label.name : "--" }}
                          </td>
                          <td
                            class="px-3 py-1 whitespace-nowrap text-wrap w-36 text-start border border-gray-300"
                          >
                            {{ task.project_name }}
                          </td>
                          <td
                            class="px-3 py-1 whitespace-nowrap text-wrap w-36 text-start border border-gray-300"
                          >
                            {{ task.assigned_to }}
                          </td>
                          <td
                            class="px-3 py-1 whitespace-nowrap text-wrap text-start w-28 border border-gray-300"
                          >
                            {{
                              task.start_date
                                ? formatDateTime(task.start_date)
                                : "---"
                            }}
                          </td>
                          <td
                            class="px-3 py-1 whitespace-nowrap text-wrap text-start w-24 border border-gray-300"
                          >
                            {{
                              task.end_date
                                ? formatDateTime(task.end_date)
                                : "---"
                            }}
                          </td>
                          <td
                            class="px-3 py-1 whitespace-nowrap text-wrap text-start w-24 border border-gray-300"
                          >
                            {{
                              task.created_at
                                ? formatDateTime(task.created_at)
                                : "---"
                            }}
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="7">No tasks found !!</td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              <PMSPagination
                :totalItems="pageTotalcount"
                :itemsPerPage="itemsPerPage"
                :currentPage="currentPage"
                @updatePage="updatePage"
              />
              <!-- <div v-if="loading" class="py-4 flex justify-center">
                <div class="relative">
                  <div
                    class="w-14 h-14 border-blue-200 border-2 rounded-full"
                  ></div>
                  <div
                    class="w-14 h-14 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"
                  ></div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import LeftNav from "@/components/LeftNavigation/LeftNav.vue";
import TopNav from "@/components/TopNav.vue";
import PMSPagination from "@/components/PMSPagination.vue";
export default {
  name: "MyTasks",
  components: {
    LeftNav,
    TopNav,
    PMSPagination,
  },
  data() {
    return {
      loading: false,
      overAllTaskList: [],
      usersList: [],
      projectsList: [],
      projectsAllList: [],

      projectId: "",
      userId: "",
      created_date: "",

      selecteddate: "",
      todayDate: new Date().toISOString().split("T")[0],

      currentPage: 1,
      itemsPerPage: 0,
      pageTotalcount: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getAccessToken",
      "getOrgUser",
      "getSelectedOrganization",
    ]),
    totalPages() {
      return Math.ceil(this.pageTotalcount / this.itemsPerPage);
    },
  },
  watch: {
    getOrgUser: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getMyTasks();
        }
      },
    },
  },
  mounted() {
    this.getMyTasks();
    this.getUsers();
    this.renderProjects();
  },
  methods: {
    renderProjects() {
      if (this.userId) {
        this.fetchProjects();
      } else {
        this.fetchAllProjects();
      }
    },
    clearFilters() {
      this.projectId = "";
      this.userId = "";
      this.created_date = "";
      this.getMyTasks();
    },
    formatDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async updatePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        await this.getMyTasks(page);
      }
    },
    async getMyTasks(val) {
      try {
        this.loading = true;
        await axios
          .get(
            `${process.env.VUE_APP_API}/task/get/list/?assigned_to__organization_user__organization_id=${this.getSelectedOrganization.organization_id}&project_card__project_id=${this.projectId}&created_at=${this.created_date}&assigned_to__organization_user_id=${this.userId}`,
            {
              params: {
                page: val,
                organization_accesskey_id:
                  this.getSelectedOrganization.organization_id,
              },
              headers: {
                Authorization: `Bearer ${this.getAccessToken}`,
              },
            }
          )
          .then((response) => {
            if (this.userId) {
              this.fetchProjects();
            } else {
              this.fetchAllProjects();
            }
            this.pageTotalcount = response.data.count;
            this.overAllTaskList = response.data.data;
            this.itemsPerPage = response.data.items_per_page;
            this.currentPage = response.data.current_page;
            console.log("overAllTaskList", this.overAllTaskList);
          })
          .catch((error) => {
            console.error("Error fetching tasks:", error);
          });
      } catch (error) {
        console.log("error fetching task details", error);
      } finally {
        this.loading = false;
      }
    },
    fetchProjects() {
      axios
        .get(
          `${process.env.VUE_APP_API}/project-member/get/?organization_user__organization_id=${this.getSelectedOrganization.organization_id}&organization_user_id=${this.userId}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        )
        .then((response) => {
          this.projectsList = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching projects:", error);
        });
    },
    fetchAllProjects() {
      axios
        .get(
          `${process.env.VUE_APP_API}/project/get/list/?workspace__organization_id=${this.getSelectedOrganization.organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        )
        .then((response) => {
          this.projectsAllList = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching projects:", error);
        });
    },
    async getUsers() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/organization-user/list/?organization_id=${this.getSelectedOrganization.organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        if (response.status === 200) {
          this.usersList = response.data.data;
        } else {
          console.error(
            "Failed to retrieve team members:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },
  },
};
</script>

<style></style>
