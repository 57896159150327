<template>
  <div
    class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black z-10 bg-opacity-30"
  >
    <div class="w-[520px] h-auto bg-white rounded-lg p-4 relative">
      <svg
        @click="$emit('closePopUp')"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="bg-red-500 h-7 text-white absolute right-3 top-3 font-bold py-1 px-1 cursor-pointer hover:bg-red-700 transition-all rounded-md"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
      <h1 class="text-3xl">Invite Members</h1>
      <p>Invite your team members into this organization</p>

      <form class="p-4 flex flex-col gap-4" @submit.prevent="inviteuser">
        <div class="flex flex-col gap-2 items-start">
          <label for="email_id"
            >Email Address <span class="text-red-600">*</span></label
          >
          <input
            type="email"
            id="email_id"
            v-model="emailaddress"
            class="border border-gray-300 h-[35px] p-1 rounded-md w-full"
            required
          />
        </div>
        <div class="flex flex-col gap-2 text-start">
          <label for=""
            >Department Category<span class="text-red-600">*</span></label
          >
          <select
            name=""
            id=""
            v-model="selected_category"
            required
            class="py-2 px-2 rounded-lg bg-white border"
          >
            <option value="" disabled selected>
              Select Department Category
            </option>
            <option :value="role.id" v-for="role in categoryList" :key="role">
              {{ role.name }}
            </option>
          </select>
        </div>
        <div class="flex flex-col gap-2 text-start">
          <label for="">Department<span class="text-red-600">*</span></label>
          <select
            name=""
            id=""
            required
            v-model="selected_role"
            class="py-2 px-2 rounded-lg bg-white border"
          >
            <option value="" disabled selected>Select Department</option>
            <template v-for="role in roleList" :key="role">
              <option
                :value="role.id"
                v-if="selected_category == role.role_category.id"
              >
                {{ role.name }}
              </option>
            </template>
          </select>
        </div>
        <div v-if="message.text" class="text-center mb-2">
          <p
            :class="{
              'text-green-600': message.status === 200,
              'text-red-600': message.status !== 200,
            }"
          >
            {{ message.text }}
          </p>
        </div>

        <div class="flex justify-center">
          <button
            type="submit"
            class="bg-[#e5f3fb] border border-[#76bdeb] text-gray-900 px-4 py-2 rounded-lg text-sm flex transition-transform hover:bg-[#76bdeb] shadow-lg"
            :disabled="loading"
          >
            {{ loading ? "Sending..." : "Send" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "InviteMember",
  data() {
    return {
      emailaddress: "",
      loading: false,
      message: {
        text: "",
        status: "",
      },
      OrgId: "",
      roleList: [],
      selected_category: "",
      selected_role: "",
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedOrganization",
      "getRoleAccess",
      "getAccessToken",
    ]),
  },
  created() {
    this.fetchCategories();
    this.getUserRole(this.getSelectedOrganization.organization_id);
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/role-category/list/?organization=${this.getSelectedOrganization.organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );
        this.categoryList = response.data.data;
        console.log(this.categoryList, "categoryList");
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async inviteuser() {
      if (this.getRoleAccess.post_access) {
        let data_json = {
          email: this.emailaddress,
          organization: this.getSelectedOrganization.organization_id,
          role: this.selected_role,
        };
        this.loading = true;
        try {
          const apiResponse = await axios.post(
            `${process.env.VUE_APP_API}/api/org-user-invite/`,
            data_json
          );

          console.log("API Response Status:", apiResponse.status);
          console.log("API Response Data:", apiResponse.data);

          if (apiResponse.status === 201) {
            this.message.text = "Mail sent successfully!";
            this.message.status = 200;
            this.emailaddress = "";

            setTimeout(() => {
              this.selected_role = "";
              this.selected_category = "";
              this.$emit("closePopUp");
              this.message.text = "";
              this.message.status = "";
            }, 2000);
          } else {
            this.message.text = "Failed to send mail.";
            this.message.status = apiResponse.status;
            setTimeout(() => {
              this.message.text = "";
              this.message.status = "";
            }, 4000);
          }
        } catch (error) {
          console.error("Error during invite:", error);
          this.message.text = "Error during invite.";
          this.message.status = error.response?.status || 500;
        } finally {
          this.loading = false;
        }
      } else {
        alert("You dont have access to invite a user");
      }
    },
    async getUserRole(organization_id) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/user-role/list/?role_category__organization_id=${organization_id}`,
          {
            params: {
              organization_accesskey_id:
                this.getSelectedOrganization.organization_id,
            },
            headers: {
              Authorization: `Bearer ${this.getAccessToken}`,
            },
          }
        );

        if (response.data) {
          this.roleList = response.data.data;
          console.log("Role List:", this.roleList);
        } else {
          console.error("Unexpected response structure:", response.data);
          this.roleList = [];
        }
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Add custom styles if necessary */
</style>
